.page {
    font-family: Georgia, 'Times New Roman', Times, serif;
    overflow: hidden;
    box-sizing: border-box;
}

.page img {
    background-color: transparent !important;
}

@media screen {
    .page {
        min-height: 100vh;
        border-top: 1px solid #ccc;
    }
}

@media print {
    * {
        background: transparent;
    }

    :global(body) {
        min-width: auto !important;
        color: #000 !important;
    }
    :global(.container-fluid),
    :global(.container-fluid .row),
    :global(.container-fluid .row .col) {
        padding: 0;
        margin: 0 !important;
    }
    :global(.navbar),
    :global(#sidebar-menu) {
        position: absolute !important;
        display: none !important;
        height: 0 !important;
        width: 0 !important;
        overflow: hidden;
        margin: -1px !important;
    }
    .page {
        margin: 0;
        padding: 0;
        width: 8.5in;
        height: 14in;
        border: initial;
        border-radius: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
}

.firstWing,
.secondWing,
.thirdWing {
    position: relative;
    z-index: 1;
}

.firstWing {
    height: 4.6in;
}

.secondWing {
    height: 4.75in;
    z-index: 2;
}

.thirdWing {
    height: 4.65in;
}

.thirdWingContent {
    text-align: center;
    padding-top: 0.125in;

    height: 100%;
    box-sizing: border-box;
    background-color: transparent;
}

.thirdWingContent * {
    font-size: 14px !important;
}

.wingContainer {
    position: relative;
    max-width: 8.5in;
    margin: 0 auto;
    padding: 0 20px;
    height: 100%;
}

.secondWing .wingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.thirdWing .wingContainer {
    padding: 0;
}

.table {
    width: 100%;
    margin: 0 auto;
}

.table thead tr th,
.table tbody tr td {
    padding: 2px;
}

.table thead tr th {
    font-weight: 600;
    border-top: 2px solid #000;
    border-bottom: 2px solid #788382;
}

.table tbody tr {
    border-bottom: 1px solid #788382;
}

.table thead tr th,
.table tbody tr td,
.table tbody tr td * {
    font-size: 13px;
}
.table thead tr th:nth-child(1) {
    width: 150px;
}

.table thead tr th:nth-child(2) {
    width: 230px;
}

.table thead tr th,
.table tbody tr td strong,
.itemsSummary strong {
    font-family: Helvetica, sans-serif;
}

.itemsSummary {
    padding: 10px 0;
}

.printLabelImage {
    width: 4in;
    transform: rotate(90deg);
}

.noShipmentLabelMessage {
    text-align: center;
    font-size: 14px;
    transform: rotateZ(90deg);
}

.messageContent {
    position: relative;
    overflow: hidden;
    width: 4.25in;
    height: 100%;
}

.messageInnerContent {
    height: 4.25in;
    width: 4.375in;
    position: absolute;
    transform: rotate(90deg);
    top: 6px;
    bottom: 0;
    right: 0;
    left: -6px;
    padding: 0 0.5in;
}

.message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    font-weight: 600;
}

.orderNo {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: auto;
}

.detailsContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 100%;
    margin: 0 auto 10px;
    padding: 20px 0 0;
}

.detailsContent * {
    font-size: 10px !important;
}

.detailsItem {
    display: flex;
}

.detailsItem strong {
    width: 100px;
    font-family: Helvetica, sans-serif;
    text-align: right;
}

.detailsItem span,
.detailsItem > div {
    margin-left: 10px;
}

.detailsLogo {
    height: 50px;
    display: block;
}

.coverLogoWrapper {
    position: relative;
    float: left;
    width: 50%;
    height: 100%;
}

.coverLogo,
.coverBadge,
.coverAddress {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(90deg);
    width: 3.5in;
}

.coverLogo {
    transform: rotate(90deg);
}

.coverBadge {
    transform: rotate(-90deg);
}

.coverAddress {
    left: auto;
    padding-top: calc(3.2in - 65px);
    transform: rotate(-90deg) translateX(20px);
}

.coverAddressWrapper {
    position: relative;
    float: right;
    width: 50%;
    height: 100%;
}
